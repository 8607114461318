import katex from 'katex';
import 'katex/dist/katex.min.css';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Methodology.css';
window.katex = katex;

function MethodologyText(props) {
  return (
    <ReactQuill
      id="qull-methodology"
      value={props?.data?.methodology || ''}
      modules={MethodologyText.modules}
      formats={MethodologyText.formats}
      bounds={'.app'}
    />
  );
}
MethodologyText.modules = {
  toolbar: [],
  clipboard: {
    matchVisual: false, // toggle to add extra line breaks when pasting HTML
  },
};

export default MethodologyText;
