import React from "react";

import Switch from "react-switch";

const ToggleButton = ({ theme, toggleTheme }) => {
  const handleChange = () => {
    toggleTheme();
  };
  return (
    <Switch
      offColor="#4d4d4d"
      onColor="#4d4d4d"
      activeBoxShadow="0 0 1px 2px #0099e0"
      offHandleColor="#fafafa"
      onHandleColor="#fafafa"
      height={24}
      width={50}
      handleDiameter={22}
      uncheckedIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 16,
            color: "orange",
            paddingRight: 0,
          }}
        >
          <span role="img" aria-label="light">🌞</span>
        </div>
      }
      checkedIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 16,
            color: "orange",
            paddingLeft: 0,
          }}
        >
          <span role="img" aria-label="dark">🌜</span>
        </div>
      }
      onChange={handleChange}
      checked={theme === "light" ? false : true}
    />
  );
};

export default ToggleButton;
