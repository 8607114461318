import React from 'react';
import './App.css';
import HomePage from './pages/HomePage.js';


function App() {
  return (
    <HomePage />
  );
}

export default App;
