import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import HeroCard from './HeroCard';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function TopSlider(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/assets/?skip=0&limit=10`
        );
        if (response.status === 200) {
          setData(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderContent = () => {
    if (data.length >= 4 || data.length === 3) {
      return (
        <Slider {...settings}>
          {data.map(
            (item) =>
              item.visible && (
                <div key={item.id}>
                  <HeroCard
                    title={item.name}
                    image={`${process.env.REACT_APP_BASE_URL}${
                      item.cover_image_url || item.cover_image
                    }`}
                    id={item.id}
                    forecastTerm={props.forecastTerm}
                  />
                </div>
              )
          )}
        </Slider>
      );
    }

    // Handle cases with 1 or 2 items, making them full width
    const colClasses = data.length === 1 ? 'col-md-4' : 'col-md-4';

    return (
      <div className="container">
        <div className="row justify-content-center">
          {data.map(
            (item) =>
              item.visible && (
                <div
                  key={item.id}
                  className={
                    colClasses + ' mb-4 d-flex justify-content-center w-card'
                  }
                >
                  <HeroCard
                    title={item.name}
                    image={`${process.env.REACT_APP_BASE_URL}${
                      item.cover_image_url || item.cover_image
                    }`}
                    id={item.id}
                    forecastTerm={props.forecastTerm}
                  />
                </div>
              )
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {data?.length > 0 && (
        <>
          <h1 className="text-center mt-4 mb-2 color-ucla">
            Current Directional Forecasts
          </h1>
          <p className="text-center mb-4 text-secondary">
            Real-time Forecasts created in the MQE Lab based on Economic
            Fundamentals.
          </p>
          {renderContent()}
        </>
      )}
    </div>
  );
}

export default TopSlider;
