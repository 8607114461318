import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

function BacktestBarChart(props) {
  const [evalmetrics, setEvalmetrics] = useState([]);
  const fetchPortFolioData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/portfolios/?skip=0&limit=10`
      );
      if (response.status === 200) {
        const findPortfolio =
          response?.data?.data?.length &&
          response.data.data.find((data) => data.id === props?.data?.id);

        fetchData(findPortfolio ? 'portfolio' : 'assets');
      }
    } catch (error) {
      console.error('Error fetching portfolio data:', error);
      return [];
    }
  };
  const fetchData = async (type) => {
    try {
      let response;
      if (type === 'assets') {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/assets/${props?.data?.id}/evalmetrics?skip=0&limit=10`
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/portfolios/${props?.data?.id}/evalmetrics?skip=0&limit=10`
        );
      }
      if (response.status === 200) {
        setEvalmetrics(response?.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if (props?.data?.id) {
      fetchPortFolioData();
    }
  }, [props]);
  return (
    <Row>
      {evalmetrics.slice(0, 3).map((item, index) => (
        <Col sm="12" md="12" lg="4">
          <ResponsiveContainer key={index} width="100%" height={200}>
            <BarChart
              width={450}
              height={200}
              stackOffset="sign"
              data={[
                {
                  name: item?.metric,
                  'MQE Forex Fund': item?.mqe,
                  'HFRI 500': item?.hfri,
                },
              ]}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                style={{ fill: props.theme === 'light' ? null : 'white' }}
                dataKey="name"
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <ReferenceLine y={0} stroke="#000" />
              <Bar dataKey="MQE Forex Fund" fill="#005687" />
              <Bar dataKey="HFRI 500" fill="#FBC138" />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      ))}
    </Row>
  );
}

export default BacktestBarChart;
