import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body} !important;
    transition: all 0.50s linear;
   }

  .bg-gradient-ucla {
    background: ${({ theme }) => theme.nav} !important;
   }
   
  .color-ucla {
       color: ${({ theme }) => theme.heading};
   }

   .color-primary {
    color: ${({ theme }) => theme.colorPrimary};
    }

  .rwt__tabpanel {
    background: ${({ theme }) => theme.nav} !important;
  }

   p {
   color:${({ theme }) => theme.text} !important;
   
  }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span {
       color:${({ theme }) => theme.headerColor} !important;
   
  }
  .equation {
    color:${({ theme }) => theme.equation} !important;
    
   }
 .rwt__tab {
     background:${({ theme }) => theme.body} !important;
     color:${({ theme }) => theme.text} ;
   }
  
   .table {
     color:${({ theme }) => theme.table} !important;
   } 

   .table thead{
      background:${({ theme }) => theme.tableHeading} !important;
    }
    .stats-table > thead > tr > th {
       color:${({ theme }) => theme.colorTh} !important;
    }
    .forecasts-table tr:not(:first-child) span {
      color: ${({ theme }) => theme.colorTh} !important;
    }
    .table-striped > tbody > tr:nth-child(2n) > td,
    .table-striped > tbody > tr:nth-child(2n) > th {
      color:${({ theme }) => theme.colorTh} !important;
    } 

    .table-striped > tbody > tr:nth-of-type(odd) {
      background-color: ${({ theme }) => theme.colorThOdd} !important;
      color:${({ theme }) => theme.colorTh} !important;
    } 
    
   .footer{
     background:${({ theme }) => theme.footer} !important;
   }
   .slick-next:before,
   .slick-prev:before
    {
      color: ${({ theme }) => theme.arrowColor} !important;
    }
    .card-body{
    background:${({ theme }) => theme.cardBody} !important;
    }
    .card{
      background:${({ theme }) => theme.card} !important;
    }
    .maeCurrencyName{
      color:${({ theme }) => theme.maeCurrencyName} !important;
    }
    .card-subtitle{
       color:${({ theme }) => theme.cardSub} !important;
    }
    .toggle-button-mae{
      color:${({ theme }) => theme.toggleButton} !important;
      border-color:${({ theme }) => theme.toggleButton} !important;
    }
   
    .tab-text{
     color: ${({ theme }) => theme.tabText};
   }
   span.recharts-legend-item-text {
     color:${({ theme }) => theme.colorTh} !important;
   }

  `;
