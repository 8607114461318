import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyB-WeyJrBlrEnJwFOeVIj0URtQuNMXLfB8",
  authDomain: "mae-forecasting.firebaseapp.com",
  databaseURL: "https://mae-forecasting.firebaseio.com",
  projectId: "mae-forecasting",
  storageBucket: "mae-forecasting.appspot.com",
  messagingSenderId: "232366810680",
  appId: "1:232366810680:web:861a132d27a88c9e81e8cd",
  measurementId: "G-NP2X96VZK1"
});

const db = firebaseApp.firestore();

export { db };
