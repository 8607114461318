import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  AreaChart,
  Label,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  ResponsiveContainer,
} from 'recharts';
import ClipLoader from 'react-spinners/ClipLoader';

function BacktestAreaChart(props) {
  const [comparisons, setComparisons] = useState();
  const [loading, setLoading] = useState(true); // Add loading state

  const fetchPortFolioData = async () => {
    setLoading(true); // Set loading to true when starting the fetch
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/portfolios/?skip=0&limit=10`
      );
      if (response.status === 200) {
        const findPortfolio =
          response?.data?.data?.length &&
          response.data.data.find((data) => data.id === props.id);
        await fetchAssets(findPortfolio ? 'portfolio' : 'assets');
      }
    } catch (error) {
      console.error('Error fetching portfolio data:', error);
    } finally {
      setLoading(false); // Set loading to false once data fetching is complete
    }
  };

  const fetchAssets = async (type) => {
    try {
      let response;
      if (type === 'assets') {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/assets/${props?.id}/backtests?skip=0&limit=10`
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/portfolios/${props?.id}/backtests?skip=0&limit=10`
        );
      }
      if (response.status === 200) {
        setComparisons(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (props?.id) {
      fetchPortFolioData();
    }
  }, [props?.id]);

  const colorYaxis = props.theme === 'light' ? null : 'white';
  const override = `
    display: block;
    margin: 0 auto;
  `;

  return (
    <>
      <h3
        className="color-ucla"
        style={{ textAlign: 'center', marginBottom: '10px', marginTop: '15px' }}
      >
        Backtest of MQE Algorithm on {props.assetName}
      </h3>
      <p className="center text-center mb-2 mx-5 color-primary">
        Profits that would have accrued from investing based on MQE signals
      </p>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 400,
          }}
        >
          <ClipLoader css={override} size={45} color={'#005687'} />
        </div>
      ) : (
        <ResponsiveContainer width="95%" height={400}>
          <AreaChart
            width={600}
            height={200}
            data={comparisons || []}
            margin={{ top: 10, right: 30, left: 30, bottom: 30 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="45%" stopColor="#FBC138" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#FBC138" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorKv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#005687" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#005687" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              name="Date"
              tick={{ textAnchor: 'end', angle: -30 }}
              height={80}
              style={{ fill: props.theme === 'light' ? null : 'white' }}
            >
              <Label
                value="Date"
                offset={-20}
                position="bottom"
                style={{ fill: props.theme === 'light' ? null : 'white' }}
              />
            </XAxis>
            <YAxis
              style={{ fill: props.theme === 'light' ? null : 'white' }}
              label={{
                fill: colorYaxis,
                value: 'Cumulative Profits in %',
                angle: -90,
                position: 'insideBottomLeft',
                x: -10,
              }}
            ></YAxis>
            <Tooltip
              contentStyle={{
                backgroundColor: '#333',
                borderRadius: '8px',
                color: '#ffffff',
              }}
            />
            <Legend />
            <Brush dataKey="Date" height={25} stroke="#005687" />
            <Area
              connectNulls={true}
              type="monotone"
              stroke="#005687"
              fill="url(#colorKv)"
              dataKey="mqe"
              name="MQE Strategy"
            />
            <Area
              connectNulls={true}
              type="monotone"
              dataKey="sp500"
              name="S&P 500"
              stroke="#5a2cf4"
              fill="url(#colorUv)"
            />
            <Area
              connectNulls={true}
              type="monotone"
              dataKey="exchange_rate"
              stroke="#FBC138"
              fill="url(#colorPv)"
              name="HFRI 500"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </>
  );
}

export default BacktestAreaChart;
