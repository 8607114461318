import React, { useState } from "react";
import { Container } from "reactstrap";

import NavbarTop from "../components/NavbarTop";
import TopSlider from "../components/TopSlider";
import HomeTabs from "../components/HomeTabs";
import Footer from "../components/Footer";
//import { RiTimerFlashFill, RiLineChartFill } from "react-icons/ri";

// DarkMode
import { ThemeProvider } from "styled-components";
import { useDarkMode } from "../darkmode/useDarkMode";
import { GlobalStyles } from "../darkmode/GlobalStyle";
import { lightTheme, darkTheme } from "../darkmode/Theme";

function HomePage() {
  const [forecastTerm, setTerm] = useState(1);

  const [theme, themeToggler, mountedComponent] = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  if (!mountedComponent) return <div />;

  return (
    <ThemeProvider theme={themeMode}>
      <>
        <GlobalStyles />
        <div>
          <NavbarTop theme={theme} toggleTheme={themeToggler} />
          <br />
          <Container>
            <div id="padding-container" style={{ padding: '0 50px' }}>
              <TopSlider forecastTerm={forecastTerm} />
            </div>
            <div id="padding-container" style={{ padding: '50px' }}>
              <HomeTabs theme={theme} forecastTerm={forecastTerm} />
            </div>
          </Container>
          <Footer />
        </div>
      </>
    </ThemeProvider>
  );
}

export default HomePage;
