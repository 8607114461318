import React from 'react';
import {
  Nav,
  Row,
  Container,
  Col,
} from 'reactstrap';


function Footer() {

  return (
    <Nav className="footer" expand="md">
      <Container fluid="sm" className="footer-container">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="5">
            <div className="copyright text-center text-xl-left text-white" style={{marginBottom: '10px'}}>
              © {new Date().getFullYear()}{" "}
              <a
                className="text-white"
                href="https://master.econ.ucla.edu/"
                target="_blank"
                rel="noopener noreferrer"
                >
                UCLA MQE. All Rights Reserved.
              </a>
            </div>
          </Col>
          <Col xl="7">
            <h5 className="text-white footer-disclaimer-heading">NOT FINANCIAL ADVICE</h5>
            <div className="text-white footer-disclaimer-text">
              The information contained on this website and the resources available for download through this website is not intended as, and shall not be understood or construed as, financial advice. I am not an attorney, accountant or financial advisor, nor am I holding myself out to be, and the information contained on this Website is not a substitute for financial advice from a professional who is aware of the facts and circumstances of your financial situation.
            </div>
          </Col>
        </Row>
      </Container>
    </Nav>
  );
}

export default Footer;
