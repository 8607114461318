import React from "react";
import { Navbar, Nav, Container, NavbarText } from "reactstrap";

import Toggle from "../darkmode/ToggleButton";

function NavbarTop({ theme, toggleTheme }) {
  return (
    <div>
      <Navbar className="bg-gradient-ucla" dark expand="md">
        <Container>
          <img alt="..." className="mqe-logo" src={require("../assets/logo.png")} />

          <Nav className="mr-auto" navbar></Nav>

          <Toggle theme={theme} toggleTheme={toggleTheme} />

          <NavbarText>
            Forecasting <br />
            Laboratory<sub> Est. 2021</sub>
          </NavbarText>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarTop;
